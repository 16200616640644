<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Yes'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-children.png'"
        :selected="personalDetails.children === 'Yes'"
        @selected="save('Yes')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'No'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-nochildren.png'"
        :selected="personalDetails.children === 'No'"
        @selected="save('No')"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'c'"
        :option="'Yes and/or Expecting'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-children.png'"
        :selected="personalDetails.children === 'expecting'"
        @selected="save('expecting')"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'

export default {
  name: 'ProfileChildren',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  mounted() {
    this.$emit('progress', '40%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    number: () => '4',
    start() {
      return this.$router.history.current.name === 'WriteMyWillChildren'
    },
    forwardTo() {
      if (this.start) return '/write_my_will/pets'
      return '/profile/pets'
    },
    backTo() {
      if (this.start) return '/write_my_will/gender'
      return '/profile/gender'
    },
    heading() {
      return 'Do you have children?'
    },
    subHeading() {
      return (
        'This includes biological and adopted children of any age (but not step children). If you have a ' +
        "child or children, but do not wish to include them in your will, you should still select 'yes'."
      )
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    isValidated() {
      if (this.personalDetails.children !== null) return true
      return false
    },
    children() {
      return this.$store.getters.people.filter(
        (person) => person.child || person.child_step
      )
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.personalDetails.children === 'No') this.resetChildren()
    next()
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          children: value
        })
      }
    },
    resetChildren() {
      this.children.forEach((person) => {
        this.$store.commit('personDelete', person.id)
      })
    }
  }
}
</script>

<style></style>
